import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useSelector from '../../../redux/typedHooks';
import { useGTMDataLayer } from '../../../hooks/useGTMDataLayer';
import { useAuth0Token } from '../../../hooks/useAuth0Token';
import { fetchInternalLink } from '../../../lib/fetchInternalLink';

interface Cta {
  url: string;
  buttonText: string;
  type: string;
  internalEndpoint?: string;
}

export const useCtaBlankExternalTracking = () => {
  const dataLayer = useGTMDataLayer();
  const history = useHistory();
  const { simpleToken } = useAuth0Token();
  const application = useSelector((state) => state.application);

  return useMemo(
    () => (cta: Cta) => async (e: React.MouseEvent<HTMLAnchorElement> & { target: { href?: string } }) => {
      const urlObject = new URL(cta.url);

      dataLayer?.push({
        event: 'cta_click',
        cta_text: cta.buttonText,
        cta_location: history.location.pathname,
        cta_url: cta.url,
        cta_hostname: urlObject.hostname,
        cta_position: 'content',
      });

      if (cta.type === 'InternalEndpoint') {
        e.preventDefault();
        const windowReference = window.open('', '_blank');

        try {
          const link = await fetchInternalLink(cta.internalEndpoint as string, application.apimContentHub, simpleToken);
          if (windowReference) {
            windowReference.location.href = link;
          } else {
            window.open(link, '_blank');
          }
        } catch (error) {
          if (windowReference) {
            windowReference.location.href = cta.url;
          } else {
            window.open(cta.url, '_blank');
          }
        }
        return false;
      }

      return true;
    },
    [application.apimContentHub, simpleToken, dataLayer, history],
  );
};
